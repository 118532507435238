import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  MatchCriteriaFieldEnum,
  MatchCriteriaItemFragment,
  MatchFilterInput,
  MatchListItemFragment,
  MatchProcessStepEnum,
} from '../../graphql/generated';
import {
  ModularSearchChip,
  ModularSearchOption,
} from '../../shared/modules/modular-searchbar/types/modular-search-options';

import { MatchesListState } from './matches-list-state.types';
import { tabActions } from '../tab-management/actions';

export const MatchesListActions = createActionGroup({
  source: 'Matches List',
  events: {
    'Temporary Query changed': props<{
      searchChips: ModularSearchChip<MatchCriteriaFieldEnum>[];
    }>(),
    'Criteria Params changed': props<{
      searchChips: ModularSearchChip<MatchCriteriaFieldEnum>[];
    }>(),
    'List Match Entered View': props<{
      id: string;
    }>(),
    'Match Received for List': props<{
      match: MatchListItemFragment;
    }>(),
    'Match Kanban Column Entered View': props<{
      processStep: MatchProcessStepEnum;
    }>(),
    'Matches Page entered': emptyProps(),
    'Matches Page left': emptyProps(),
    'Load More Matches Clicked': emptyProps(),
    'Tab initialized': emptyProps(),
    'Search history Opened': emptyProps(),
    'Search history Closed': emptyProps(),
    'Toggle View': emptyProps(),
    'Load Matches per ProcessSteps': emptyProps(),
    'Match Kanban Column ENTRY Entered View': emptyProps(),
    'Match Kanban Column PREPARATION Entered View': emptyProps(),
    'Match Kanban Column RECOMMENDATION Entered View': emptyProps(),
    'Match Kanban Column EXAMINATION Entered View': emptyProps(),
    'Match Kanban Column CONCLUSION Entered View': emptyProps(),
    'Match update Order': props<{
      processStep: MatchProcessStepEnum;
      matchIds: string[];
    }>(),
    updateTab: props<{
      tab: MatchesListState['tabs'][number];
    }>(),
    resetMatchesInView: emptyProps(),
    updateMatchStatusIfUnread: props<{
      match: MatchListItemFragment;
    }>(),
  },
});

export const MatchesApiActions = createActionGroup({
  source: 'Matches API',
  events: {
    'Loading Match Ids succeeded': props<{
      matchIds: ReadonlyArray<string>;
      totalCount: number;
    }>(),
    'Loading Matches errored': emptyProps(),
    'Loading Matches started': emptyProps(),
    'Received Match List Item Data': props<MatchListItemFragment>(),
    'Loading pinned tab count succeeded': props<{
      tabId: string;
      count: number;
    }>(),
    'Loading pinned tab count errored': props<{ tabId: string }>(),
    'Loading search options succeeded': props<{
      searchOptions: ModularSearchOption<MatchCriteriaFieldEnum>[];
    }>(),
    'Match Search History Cleared': emptyProps(),
    'Match Search History Adding Started': props<{
      filter: MatchFilterInput;
    }>(),
    'Match Search History Adding Succeeded': props<{
      updatedFilterHistory: ReadonlyArray<
        ReadonlyArray<MatchCriteriaItemFragment>
      >;
    }>(),
    'Match Search History Adding Errored': props<{
      error: any;
    }>(),
    'Match Search History Clearing Started': emptyProps(),
    'Match Search History Clearing Succeeded': emptyProps(),
    'Match Search History Clearing Errored': props<{
      error: any;
    }>(),

    'Loading Match Ids By ProcessStep succeeded': props<{
      matchIds: ReadonlyArray<string>;
      // only contains total count if all process step counts are present therefore cumulated
      totalItemsCount?: number;
      processStep: MatchProcessStepEnum;
    }>(),

    'Loading Unread Matches succeeded': props<{
      processStep: MatchProcessStepEnum;
      hasUnreadMatches: boolean;
    }>(),
  },
});

export const MatchListTabableActions = tabActions<MatchesListState, 'matches'>(
  'matches',
);
